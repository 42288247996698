@font-face {
  font-family: "gothammedium";
  src: url("./fonts/gotham-medium-webfont.woff2") format("woff2"),
    url("./fonts/gotham-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight";
  src: url("./fonts/gotham-light-webfont.woff2") format("woff2"),
    url("./fonts/gotham-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("./fonts/gotham-book-webfont.woff2") format("woff2"),
    url("./fonts/gotham-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("./fonts/gotham-bold-webfont.woff2") format("woff2"),
    url("./fonts/gotham-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamblack";
  src: url("./fonts/gotham-black-webfont.woff2") format("woff2"),
    url("./fonts/gotham-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "gothambook", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
